import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { IManagerFormValue } from '@manager'

export const useApiLeaseCardEvaluateMutation = (
  divisionId: string,
  leaseId: string,
  fieldGroupId: number,
) => {
  const queryClient = useQueryClient()

  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return useMutation({
    mutationFn: (body: IManagerFormValue) => {
      // If a previous request is in progress, abort it
      if (controller) {
        controller.abort()
      }

      // Create a new AbortController and signal for the upcoming request
      controller = new AbortController()
      signal = controller.signal

      return fetchAPI<IManagerFormValue>(
        `/companies/${divisionId}/leases/${leaseId}/evaluate/node/${fieldGroupId}`,
        {
          method: 'POST',
          body,
          signal,
        },
      )
    },
  })
}
