import {
  FieldUsage,
  type IManagerFormValue,
  mergeForm,
  useManagerFormValue,
} from '@manager'
import { cloneFnJSON } from '@vueuse/core'

export const useManagerNodeSubmit = (usage: FieldUsage | null | undefined) => {
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  const fieldGroupId = getCurrentFieldGroupId()

  const { formValue } = useManagerFormValue()

  const { mutateAsync: evaluateAsync } = useApiLeaseCardEvaluateMutation(
    divisionId,
    leaseId,
    fieldGroupId,
  )

  const payloadSnapshot = ref<IManagerFormValue>()

  const onSubmit = useFieldSubmitHandler<undefined>(
    'manager',
    async () => {
      const _formValue = cloneFnJSON(formValue.value ?? {})
      if (_formValue) return evaluateAsync(_formValue)
      return evaluateAsync(payloadSnapshot.value!)
    },
    {
      errorMessage: 'Failed to evaluate',
      beforeSubmit() {
        if (!usage || usage !== FieldUsage.INTERACTIVE) {
          return false
        }

        payloadSnapshot.value = cloneFnJSON(formValue.value ?? {})

        if (!payloadSnapshot.value) return false
      },
      onSuccess: async (response) => {
        // Compare the current form value with the request payload.
        // If some value has changed while the request was ongoing,
        // this specific property should not be merged.
        const diff = diffManagerForm(payloadSnapshot.value!, formValue.value!)
        const hasDiff = Object.keys(diff).length > 0

        if (response) {
          mergeForm({
            target: formValue.value,
            source: cloneFnJSON(response),
            ignore: diff,
            isEvaluation: true,
          })
        }

        payloadSnapshot.value = undefined
      },
    },
  )

  return {
    onSubmit: useDebounceFn(onSubmit, 300),
  }
}
